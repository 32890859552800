<template>
  <div class="users px-6 py-5">
    <div class="d-flex align-center justify-space-between">
      <span class="font-12 white-grey--text">{{ hosts.length }} Hosts</span>
      <div>
        <div class="d-flex">
          <v-btn dark color="#555891" @click="newHandler"
            ><span class="font-12">New Host Account</span></v-btn
          >
        </div>
      </div>
    </div>
        <!-- table -->
        <v-data-table
          :headers="headers"
          :items="[{},{},{},{}]"
          item-key="_id"
          class="users-table elevation-0 mt-4"
          v-if="loading"
        >
          <template v-slot:item>
            <tr>
              <td v-for="(item, i) in [1, 1, 1, 1]" :key="i">
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table
          v-if="!loading"

      :headers="headers"
      :items="hosts"
      item-key="_id"
      class="users-table elevation-0 mt-4"
    >
      <!-- name & email -->
      <template v-slot:item.name="{ item }">
        <span class="d-block font-13 white-grey--text">{{ item.name }}</span>
        <span class="d-block font-12 grey--text">{{ item.email }}</span>
      </template>

      <!-- avatar -->
      <template v-slot:item.avatar="{ item }">
        <div class="my-2">
          <img
            v-if="item.picture"
            class="user-avatar"
            :src="item.picture.url"
            alt=""
          />
          <img v-else class="user-avatar" :src="avatar" alt="" />
        </div>
      </template>

      <!-- last login -->
      <template v-slot:item.lastLogin="{ item }">
        <span class="d-block font-13 white-grey--text">{{
          $moment(item.lastLogin).format("LLLL")
        }}</span>
      </template>

      <!-- create time -->
      <template v-slot:item.created_at="{ item }">
        <span class="d-block font-13 white-grey--text">{{
          $moment(item.created_at).format("LLLL")
        }}</span>
      </template>

      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <div>
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" v-bind="attrs" v-on="on"
                >mdi-dots-vertical</v-icon
              >
            </template>
            <v-list class="py-0 skyblue">
              <router-link tag="div" :to="`/hosts/account/${item._id}`">
                <div class="menu-item py-2 px-2 cursor-pointer">
                  <span class="font-14">View More</span>
                </div>
              </router-link>
              <div class="divider-light"></div>
              <div
                class="menu-item py-2 px-2 cursor-pointer"
                @click="editHandler(item)"
              >
                <span class="font-14">Edit Host</span>
              </div>
              <div class="divider-light"></div>
              <div
                class="menu-item py-2 px-2 cursor-pointer"
                @click="editDialog = true"
              >
                <span class="font-14">Send Notification</span>
              </div>
              <div class="divider-light"></div>

              <div
                class="menu-item py-2 px-2 cursor-pointer"
                @click="deleteConfirm(item)"
              >
                <span class="font-14">Delete Account</span>
              </div>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="hostDialog" max-width="500" content-class="white">
      <div class="pa-3 py-5">
        <new-host
        :key="hostDialog"
          @close="hostDialog = false"
          :isEdit="isEdit"
          :item="selectedItem"
          @fetchData="fetchDataHandler"
        ></new-host>
      </div>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import NewHost from "@/components/dialogs/NewHost";
export default {
  components: {
    NewHost,
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    headers: [
      {
        text: "Avatar",
        align: "start",
        sortable: false,
        value: "avatar",
        width: "5%",
      },
      { text: "First Name", value: "firstName", width: "35%" },
      { text: "Last Name", value: "lastName", width: "35%" },
      { text: "Phone", value: "phone", width: "35%" },
      { text: "Email", value: "email", width: "35%" },
      { text: "Actions", value: "actions", width: "10%" },
    ],
    hosts: [],
    hostDialog: false,
    deleteDialog: false,
    itemID: "",
    selectedItem: "",
    isEdit: false,
    loading: true,
  }),
  methods: {
    newHandler() {
      this.hostDialog = true;
      this.isEdit = false;
      this.selectedItem = "";
    },
    editHandler(item) {
      this.hostDialog = true;
      this.isEdit = true;
      this.selectedItem = item;
    },
    deleteConfirm(item) {
      this.deleteDialog = true;
      this.itemID = item;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(
        `/host/delete/new/${this.itemID._id}`
      );

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success",
        });
        this.deleteDialog = false;
        await this.fetchHosts();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error",
        });
      }
    },
    async fetchDataHandler() {
      this.hostDialog = false;
      await this.fetchHosts();
    },
    async fetchHosts() {
      this.loading = true
      let { data } = await this.$axios.get("host/all/new?pageSize=1000");
      this.loading = false
      if (data) {
        this.hosts = data.data;
      }
    },
  },
  async created() {
    await this.fetchHosts();
  },
};
</script>

<style lang="scss">
.users {
  overflow: scroll;
  height: 100vh;
  background: #fbfbfd;

  .users-table {
    background: #eaecee;
  }

  .mdi-arrow-up {
    display: none;
  }
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
