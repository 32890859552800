<template>
  <div>
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div class="d-flex align-center justify-space-between b-bottom mb-6">
      <span class="d-block font-16 font-500">New Host Account</span>
      <v-icon @click="$emit('close')" size="20" color="primary"
        >mdi-close</v-icon
      >
    </div>
    <v-form v-model="valid" @submit.prevent="submitHandler">
      <v-row>
        <!-- profile image -->
        <v-col cols="12">
          <div class="upload-image">
            <img :src="newImage" alt="" />

            <div class="upload-btn cursor-pointer">
              <v-icon dark color="grey">mdi-upload</v-icon>
              <span class="font-15 grey--text">Profile Image</span>
              <v-file-input
                accept="image/*"
                label="File input"
                class="file-upload"
                @change="newImageHandler"
                hide-details="true"
              ></v-file-input>
            </div>
          </div>
        </v-col>

        <!-- first name -->
        <v-col class="py-1" cols="12" md="6">
          <base-input
            placeholder="First Name"
            v-model="obj.firstName"
          ></base-input>
        </v-col>

        <!-- last name -->
        <v-col class="py-1" cols="12" md="6">
          <base-input
            placeholder="Last Name"
            v-model="obj.lastName"
          ></base-input>
        </v-col>

        <!-- email -->
        <v-col class="py-1" cols="12" md="6">
          <base-input
            type="email"
            placeholder="Email"
            v-model="obj.email"
            :rules="emailRules"
          ></base-input>
        </v-col>

        <!-- password -->
        <v-col class="py-1" cols="12" md="6" v-if="!isEdit">
          <base-input
            type="password"
            placeholder="Password"
            v-model="obj.password"
          ></base-input>
        </v-col>

        <!-- last name -->
        <v-col class="py-1" cols="12" md="6">
          <base-input
            placeholder="Promo"
            v-model="obj.promo"
            :rules="[]"
          ></base-input>
        </v-col>

        <!-- Country -->
        <v-col class="py-1" cols="12" md="6">
          <base-select
            :items="countries"
            placeholder="Country"
            v-model="obj.country"
          ></base-select>
        </v-col>

        <!-- city -->
        <v-col class="py-1" cols="12" md="6">
          <base-input placeholder="City" v-model="obj.city"></base-input>
        </v-col>

        <!-- phone -->
        <v-col class="py-1" cols="12" md="6">
          <base-input placeholder="Phone" v-model="obj.phone"></base-input>
        </v-col>

        <!-- bio -->
        <v-col class="py-1" cols="12">
          <base-textarea placeholder="BIO" v-model="obj.bio[language]"></base-textarea>
        </v-col>

        <!-- message -->
        <v-col class="py-1" cols="12">
          <base-textarea
            placeholder="Message"
            v-model="obj.message[language]"
            :rules="[]"
          ></base-textarea>
        </v-col>

        <!-- featured -->
        <v-col class="py-1" cols="12">
          <v-checkbox
            :hide-details="true"
            label="Featured"
            dense
            v-model="obj.featured"
          ></v-checkbox>
        </v-col>

        <!-- submit -->
        <v-col class="py-1" cols="12">
          <v-btn
            type="submit"
            block
            :disabled="!valid"
            :dark="valid"
            color="secondary"
            ><span class="font-13">Submit</span></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    valid: false,
    obj: { featured: false, message: {}, bio: {} },
    countries: [{ title: "Lebanon", value: "LB" }],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    newImage: "",
    imgFile: "",
    language: "en"
  }),
  methods: {
    newImageHandler(file) {
      this.newImage = URL.createObjectURL(file);
      this.imgFile = file;
    },
    async submitHandler() {
      if (!this.newImage) {
        this.$store.dispatch("showSnack", {
          text: "Please Upload Profile Picture",
          color: "error",
        });
        return;
      }

      this.obj.country = this.obj.country.value;

      // request to create host id
      let resCreate;
      if (this.isEdit) {
        resCreate = { data: { data: { _id: this.item._id } } };

        const { data } = await this.$axios.put(
          `host/edit/new/${resCreate.data.data._id}`,
          {
            ...this.obj,
          }
        );
        if (this.newImage && this.imgFile) {
          // upload image
          let formData = new FormData();
          formData.append("hostId", resCreate.data.data._id);
          formData.append("coverImage", this.imgFile);

          // request to upload image
          await this.$axios.post(`media/host/cover`, formData, {
            "content-type": "multipart/form-data",
          });
        }
        if (data) {
          this.$store.dispatch("showSnack", {
            text: "Host Edited Successfully",
            color: "success",
          });
          this.obj = { featured: false };
          this.$emit("fetchData");
        } else {
          this.$store.dispatch("showSnack", {
            text: "Something went wrong",
            color: "error",
          });
        }
      } else {
        resCreate = await this.$axios.post(`host/register`, { ...this.obj });
        if (resCreate.data) {
          if (this.newImage && this.imgFile) {
            // upload image
            let formData = new FormData();
            formData.append("hostId", resCreate.data.data._id);
            formData.append("coverImage", this.imgFile);

            // request to upload image
            let responseImage = await this.$axios.post(
              `media/host/cover`,
              formData,
              {
                "content-type": "multipart/form-data",
              }
            );

            if (responseImage.data) {
              this.$store.dispatch("showSnack", {
                text: "Host Created Successfully",
                color: "success",
              });
              this.obj = { featured: false };
              this.$emit("fetchData");
            } else {
              this.$store.dispatch("showSnack", {
                text: "Something went wrong",
                color: "error",
              });
            }
          }
        }
      }
    },
  },
  created() {
    this.language = this.$root.language;
    if (this.isEdit) {
      this.newImage = this.item.picture.url;
      this.obj = { ...this.item };
    }
  },
};
</script>

<style></style>
